@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1, h2, h3, h4, h5, h6 {
        font-family: Exo2-SemiBold;
        @apply text-bright-turquoise;
    }

    h1:first-child, h2:first-child, h3:first-child {
        margin-top: 0;
    }

    h1 {
        font-size: 45px;
    }

    h2 {
        font-size: 25px;
        margin: 20px 0;
    }

    h3 {
        font-size: 20px;
        margin-top: 30px;
    }

    body {
        font-size: 20px;
    }

    code {
        overflow-x: scroll;
    }
}

@layer utilities {
    .content-width {
        @apply w-11/12 lg:w-[900px]
    }
}

@layer components {
    .btn {
        @apply px-5 py-3 no-underline text-white font-header text-2xl shadow-custom leading-none;
    }
    .btn-primary {
        @apply bg-lime-500 btn;
    }
    .btn-secondary {
        @apply bg-stone-600 btn;
    }
    .btn-danger {
        @apply bg-red-700 btn;
    }
    
    .tile {
        @apply my-12 bg-slate-900 p-10 relative rounded-md;
    }
}