body {
    @apply text-white;
}

.navbar {
    color: white;
    font-size: 33px;

    .navbar-brand {
        font-size: 65px;
    }
}

code {
    font-family: Inconsolata;
    font-size: 20px !important;
    /* Override Ckeditors default */
    display: block;
    padding: 30px;
    color: white;
    margin: 20px 0;
    @apply bg-slate-800;
}

table {
    tr:nth-child(2n) {
        @apply bg-slate-800;
    }

    td {
        @apply px-3 py-2;
    }
}

.ck-editor {
    color: black;
}