@font-face {
    font-family: 'Inconsolata';
    src: url('../Fonts/Inconsolata.ttf');
}

@font-face {
    font-family: 'Aldrich';
    src: url('../Fonts/Aldrich.ttf');
}

@font-face {
    font-family: 'Exo2';
    src: url('../Fonts/Exo2.ttf');
}

@font-face {
    font-family: 'Exo2-SemiBold';
    src: url('../Fonts/Exo2-SemiBold.ttf');
    font-weight: 600;
}