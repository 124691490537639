.parallax-container {
    z-index: -1;
    position: fixed;
    top: 0;
    opacity: 0.7;
}

.overflow-center {
    transform: translateX(calc(-50% + 50vw));
}
